const dev = {
    okta: {
        okta_url: "https://atg.oktapreview.com",
        client_id: "0oa293qedg1A8pLmE0h8",
        redirect_uri: "/auth/login",
        platform_url: "https://platform-api.security-engineering-np.coxautoinc.com",
    },
};

const prod = {
    okta: {
        okta_url: "https://coxauto.okta.com/oauth2/default",
        client_id: "0oa1tomxc4zv8uBrC0h8",
        redirect_uri: "/auth/login",
        platform_url: "https://platform-api.security-engineering.coxautoinc.com",
    },
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
    ...config,
};
